import React, { Suspense, lazy } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

// import ScrollToTop from "./components/scroll-to-top/ScrollTop.component"

const HomePage = lazy(() => import('./pages/home/Home.page'));
const AboutMePage = lazy(() => import('./pages/about-me/AboutMe.page'));
const PortfolioPage = lazy(() => import('./pages/portfolio/Portfolio.page'));
const PortfolioDetailPage = lazy(() => import('./pages/portfolio-detail/PortfolioDetail.page'));

const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        {/* <ScrollToTop> */}
        <Route
          path={process.env.PUBLIC_URL + '/'}
          element={
            <Suspense fallback={<div>Loading...</div>}>
              <HomePage />
            </Suspense>
          }
        />
        <Route
          path={process.env.PUBLIC_URL + '/home'}
          element={
            <Suspense fallback={<div>Loading...</div>}>
              <HomePage />{' '}
            </Suspense>
          }
        />
        <Route
          path={process.env.PUBLIC_URL + '/about'}
          element={
            <Suspense fallback={<div>Loading...</div>}>
              <AboutMePage />
            </Suspense>
          }
        />
        <Route
          path={process.env.PUBLIC_URL + '/portfolio'}
          element={
            <Suspense fallback={<div>Loading...</div>}>
              <PortfolioPage />
            </Suspense>
          }
        />
        <Route
          path={process.env.PUBLIC_URL + '/portfolio/:slug'}
          element={
            <Suspense fallback={<div>Loading...</div>}>
              <PortfolioDetailPage />
            </Suspense>
          }
        />

        {/* </ScrollToTop> */}
      </Routes>
    </BrowserRouter>
  );
};

export default App;
